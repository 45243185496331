import { FormWrapper } from './FormWrapper'
import { Card, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCheckCircle, faInfoCircle, faNotEqual, faPaintBrush, faPlus, faThumbsDown, faThumbsUp, faX, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

type NeedsFormValues = {
  needDesignServices: string,
  needHostingServices: boolean,
  needMaintenanceServices: boolean,
  needSEOServices: boolean
}

type NeedsFormProps = NeedsFormValues & {
  updateFields: (fields: Partial<NeedsFormValues>) => void
}

const designNeedChoices = [
  {
    name: 'none',
    label: 'Aucune',
    color: 'danger',
    icon: <FontAwesomeIcon icon={faX} />
  },
  {
    name: 'a_bit',
    label: 'Un peu',
    color: 'warning',
    icon: <FontAwesomeIcon icon={faPlus} />,
  },
  {
    name: 'a_lot',
    label: 'Beaucoup',
    color: 'success',
    icon: <><FontAwesomeIcon icon={faPlus} />&nbsp;<FontAwesomeIcon icon={faPlus} /></>
  }
]

export function NeedsForm({ needDesignServices, needHostingServices, needMaintenanceServices, needSEOServices, updateFields }: NeedsFormProps) {
  return (
    <FormWrapper title='Ce que vous attendez de nous' subtitle='test'>
      <Form.Group className='mb-2'>
        <Form.Label>Besoin d'une aide pour le design de votre site ? 
        <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body dangerouslySetInnerHTML={{
                  __html:
                    "<strong>Par exemple</strong> : <ul><li>Propositions de designs</li><li>Établissement de la charte graphique</li><li>Création de la maquette</li></ul>"
                }} className='p-3' />
              </Popover>
            } >
            <span><FontAwesomeIcon icon={faInfoCircle} /></span>
          </OverlayTrigger>
          </Form.Label>
        <div className="row">
          {
            designNeedChoices.map((choice, idx) =>
              <Col lg={3} md={4} xs={6} className='mb-3' key={idx}>
                <Card className={`quote-card pointer ${needDesignServices === choice.label ? 'selected-quote-card' : ''}`} onClick={e => updateFields({ needDesignServices: choice.label })}>
                  <div className="card-body p-3 px-5">
                    <div className="text-center">
                      <p className={`h2 text-${choice.color}`}>{choice.icon}</p>
                      <p className='mb-0'>{choice.label}</p>
                    </div>
                  </div>
                </Card>
              </Col>
            )
          }
        </div>
      </Form.Group>
      <Form.Group className='mb-2'>
        <Form.Label>Besoin qu'on s'occupe de la mise en ligne ?
          <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body dangerouslySetInnerHTML={{
                  __html:
                    "<strong>Par exemple</strong> : <ul><li>Achat du nom de domaine</li><li>Hébergement</li><li>Création d'une adresse mail</li><li>Mise en ligne</li></ul>"
                }} className='p-3' />
              </Popover>
            } >
            <span><FontAwesomeIcon icon={faInfoCircle} /></span>
          </OverlayTrigger>
        </Form.Label>
        <div className="row">
        <Col md={3} xs={6} className='mb-3'>
            <Card className={`quote-card pointer ${!needHostingServices ? 'selected-quote-card' : ''}`} onClick={e => updateFields({ needHostingServices: false })}>
              <div className="card-body py-3 px-2">
                <div className="text-center">
                  <p className='mb-0 text-danger'><FontAwesomeIcon icon={faX} /></p>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={3} xs={6} className='mb-3'>
            <Card className={`quote-card pointer ${needHostingServices ? 'selected-quote-card' : ''}`} onClick={e => updateFields({ needHostingServices: true })}>
              <div className="card-body py-3 px-2">
                <div className="text-center">
                  <p className='mb-0 text-success'><FontAwesomeIcon icon={faCheck} /></p>
                </div>
              </div>
            </Card>
          </Col>
        </div>
      </Form.Group>
      <Form.Group className='mb-2'>
        <Form.Label>Besoin qu'on assure la maintenance de votre produit ?
          <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body dangerouslySetInnerHTML={{
                  __html:
                    "<strong>Par exemple</strong> : <ul><li>Corrections de bugs</li><li>Mises à jour</li><li>Checks de sécurité</li><li>Ajout de fonctionnalités</li></ul>"
                }} className='p-3' />
              </Popover>
            } >
            <span><FontAwesomeIcon icon={faInfoCircle} /></span>
          </OverlayTrigger>
        </Form.Label>
        <div className="row">
        <Col md={3} xs={6} className='mb-3'>
            <Card className={`quote-card pointer ${!needMaintenanceServices ? 'selected-quote-card' : ''}`} onClick={e => updateFields({ needMaintenanceServices: false })}>
              <div className="card-body py-3 px-2">
                <div className="text-center">
                  <p className='mb-0 text-danger'><FontAwesomeIcon icon={faX} /></p>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={3} xs={6} className='mb-3'>
            <Card className={`quote-card pointer ${needMaintenanceServices ? 'selected-quote-card' : ''}`} onClick={e => updateFields({ needMaintenanceServices: true })}>
              <div className="card-body py-3 px-2">
                <div className="text-center">
                  <p className='mb-0 text-success'><FontAwesomeIcon icon={faCheck} /></p>
                </div>
              </div>
            </Card>
          </Col>
        </div>
      </Form.Group>
      <Form.Group className='mb-4' >
        <Form.Label>
          Besoin qu'on s'occupe du contenu et du SEO ?
          <OverlayTrigger
            trigger={["focus", "hover"]}
            placement="bottom"
            overlay={
              <Popover>
                <Popover.Body dangerouslySetInnerHTML={{
                  __html:
                    "<strong>Par exemple</strong> : <ul><li>Rédaction de textes</li><li>Stratégies SEO</li><li>Référencement amélioré</li></ul>"
                }} className='p-3' />
              </Popover>
            } >
            <span><FontAwesomeIcon icon={faInfoCircle} /></span>
          </OverlayTrigger>
        </Form.Label>
        <div className="row">
          <Col md={3} xs={6} className='mb-3'>
            <Card className={`quote-card pointer ${!needSEOServices ? 'selected-quote-card' : ''}`} onClick={e => updateFields({ needSEOServices: false })}>
              <div className="card-body py-3 px-2">
                <div className="text-center">
                  <p className='mb-0 text-danger'><FontAwesomeIcon icon={faX} /></p>
                </div>
              </div>
            </Card>
          </Col>
          <Col md={3} xs={6} className='mb-3'>
            <Card className={`quote-card pointer ${needSEOServices ? 'selected-quote-card' : ''}`} onClick={e => updateFields({ needSEOServices: true })}>
              <div className="card-body py-3 px-2">
                <div className="text-center">
                  <p className='mb-0 text-success'><FontAwesomeIcon icon={faCheck} /></p>
                </div>
              </div>
            </Card>
          </Col>
        </div>
      </Form.Group>
    </FormWrapper>
  )
}