import { Col, Form, Row } from 'react-bootstrap'
import { FormWrapper } from './FormWrapper'
import ReCAPTCHA from 'react-google-recaptcha'
import React, { useRef, useState } from 'react'
import axios from 'axios'

type CoordinatesFormValues = {
  status: string,
  specificStatus: string,
  companyName: string,
  organizationName: string,
  publicAdministrationName: string,
  lastName: string,
  firstName: string,
  phone: string,
  mail: string
}

type CoordinatesFormProps = CoordinatesFormValues & {
  updateFields: (fields: Partial<CoordinatesFormValues>) => void,
  reCaptchaCheck: (e: boolean) => void
}

export function CoordinatesForm({
  status,
  specificStatus,
  companyName,
  organizationName,
  publicAdministrationName,
  lastName,
  firstName,
  phone,
  mail,
  updateFields,
  reCaptchaCheck }: CoordinatesFormProps) {

  const captchaRef = React.useRef<ReCAPTCHA>() // maybe not important

  async function onChange(value: any) {

    axios.post(process.env.REACT_APP_API_URL + '/verify-recaptcha-token', { params: { token: value } })
      .then(res => reCaptchaCheck(res.data))
  }


  return (
    <FormWrapper title="Pour finir, dites-nous qui vous êtes" subtitle="C'est plus pratique pour commencer une conversation">
      <Row className='mb-4'>
        <Form.Group as={Col} lg={6}>
          <Form.Label>Votre statut*</Form.Label>
          <Form.Select required aria-label="Default select example" value={status} onChange={e => updateFields({ status: e.target.value })} className='pointer'>
            <option value="">Sélectionner</option>
            <option value="Entreprise">Entreprise</option>
            <option value="Association">Association</option>
            <option value="Auto-entrepreneur">Auto-entrepreneur</option>
            <option value="Administration publique">Administration publique</option>
            <option value="Autre">Autre</option>
            <option value="Pas de structure">Je n'ai pas encore de structure</option>
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col} lg={6}>
          {
            (status === "Entreprise" &&
              <>
                <Form.Label>Entreprise</Form.Label>
                <Form.Control
                  value={companyName}
                  onChange={e => updateFields({ companyName: e.target.value })}
                  type="text"
                  placeholder='Votre entreprise'
                  required />
              </>)
            ||
            (status === "Association" &&
              <>
                <Form.Label>Association</Form.Label>
                <Form.Control
                  value={organizationName}
                  onChange={e => updateFields({ organizationName: e.target.value })}
                  type="text"
                  placeholder='Votre association'
                  required />
              </>)
            ||
            (status === "Administration publique" &&
              <>
                <Form.Label>Administration</Form.Label>
                <Form.Control
                  value={publicAdministrationName}
                  onChange={e => updateFields({ publicAdministrationName: e.target.value })}
                  type="text"
                  placeholder='Votre administration publique' />
              </>)
            ||
            (status === "Autre" &&
              <>
                <Form.Label>Autre</Form.Label>
                <Form.Control
                  value={specificStatus}
                  onChange={e => updateFields({ specificStatus: e.target.value })}
                  type="text"
                  placeholder='Décrivez votre statut'
                  required />
              </>)
          }
        </Form.Group>
      </Row>
      <Row className='mb-4'>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Nom*</Form.Label>
          <Form.Control
            value={lastName}
            onChange={e => updateFields({ lastName: e.target.value })}
            type="text"
            placeholder='Nom'
            required />
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Prénom*</Form.Label>
          <Form.Control
            value={firstName}
            onChange={e => updateFields({ firstName: e.target.value })}
            type="text"
            placeholder='Prénom'
            required />
        </Form.Group>
      </Row>
      <Row className='mb-4'>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Téléphone*</Form.Label>
          <Form.Control
            value={phone}
            onChange={e => updateFields({ phone: e.target.value })}
            type="number"
            placeholder='06 12 34 56 78'
            required />
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>E-mail*</Form.Label>
          <Form.Control
            value={mail}
            onChange={e => updateFields({ mail: e.target.value })}
            type="text"
            placeholder='exemple@gmail.com'
            required />
        </Form.Group>
      </Row>
      <ReCAPTCHA className='mb-3' sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string} onChange={(e) => onChange(e)} ref={captchaRef as React.RefObject<ReCAPTCHA>} />
    </FormWrapper >
  )
}