import { useEffect, useState } from 'react'
import Navbar from '../components/global/Navbar'
import { Button, Col, Form } from 'react-bootstrap'
import { useMultiStepForm } from '../components/tunnel/hooks/useMultiStepForm'
import { IdeaForm } from '../components/tunnel/IdeaForm'
import { ProductForm } from '../components/tunnel/ProductForm'
import { NeedsForm } from '../components/tunnel/NeedsForm'
import { CoordinatesForm } from '../components/tunnel/CoordinatesForm'
import { useMutation } from '@apollo/client'
import { CREATE_LEAD, CREATE_QUOTE } from '../graphql/mutations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faArrowRightLong, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { scrollToTop } from '../tools/common'

type FormData = {
  neededProducts: string,
  category: string,
  description: string,
  wantedFeatures: string,
  customFeatures?: string,
  needBackoffice: boolean,
  needDesignServices: string,
  needHostingServices: boolean,
  needMaintenanceServices: boolean,
  needSEOServices: boolean,
  status: string,
  specificStatus: string,
  companyName: string,
  organizationName: string,
  publicAdministrationName: string,
  lastName: string,
  firstName: string,
  phone: string,
  mail: string
}

export const INITIAL_VALUES: FormData = {
  neededProducts: "",
  category: "",
  description: "",
  wantedFeatures: "",
  customFeatures: undefined,
  needBackoffice: false,
  needDesignServices: "Aucune",
  needHostingServices: false,
  needMaintenanceServices: false,
  needSEOServices: false,
  status: "",
  specificStatus: "",
  companyName: "",
  organizationName: "",
  publicAdministrationName: "",
  lastName: "",
  firstName: "",
  phone: "",
  mail: ""
}

const Tunnel = () => {
  const [values, setValues] = useState(INITIAL_VALUES)

  const updateFields = (fields: Partial<FormData>) => {
    setValues((prev: FormData) => {
      return { ...prev, ...fields }
    })
  }

  const [isValidReCaptcha, setIsValidReCaptcha] = useState(false)

  const handleReCaptcha = (value: boolean) => {
    setIsValidReCaptcha(value)
  }

  const {
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    currentStepIndex,
    stepNumber
  } = useMultiStepForm([
    <IdeaForm {...values} updateFields={updateFields} />,
    <ProductForm {...values} updateFields={updateFields} />,
    <NeedsForm {...values} updateFields={updateFields} />,
    <CoordinatesForm {...values} updateFields={updateFields} reCaptchaCheck={handleReCaptcha} />
  ])

  const [validated, setValidated] = useState({ [currentStepIndex]: false })

  // useEffect(() => setValidated({ currentStepIndex: false }), [validated]) // falsy validated on each next

  const [createUser, { data: dataUser, error: errorUser }] = useMutation(CREATE_LEAD)
  const [createQuote, { data: dataQuote, error: errorQuote }] = useMutation(CREATE_QUOTE)

  const onSubmit = (e: any) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (values.neededProducts.length === 0) { // checking before next
        e.stopPropagation();
      }
      if (!isLastStep) return next()

      // Last step case
      createUser({
        variables: {
          data: {
            email: values.mail,
            firstname: values.firstName,
            lastname: values.lastName,
            phone: values.phone,
            status: values.status,
            companyName: values.companyName || null,
            organizationName: values.organizationName || null,
            publicAdministrationName: values.publicAdministrationName || null,
          }
        }
      }).then(() => {
        createQuote({
          variables: {
            data: {
              customFeatures: values.customFeatures,
              features: values.wantedFeatures,
              lead: values.mail,
              needBackOffice: values.needBackoffice,
              needDesignServices: values.needDesignServices,
              needHostingServices: values.needHostingServices,
              needMaintenanceServices: values.needMaintenanceServices,
              needSEOServices: values.needSEOServices,
              products: values.neededProducts,
              category: values.category,
              description: values.description
            }
          }
        })
      })
    }
    setValidated({ ...validated, [currentStepIndex]: true });
  }

  const navigate = useNavigate()
  useEffect(() => {
    if (dataQuote && dataQuote.createQuote.id !== undefined) {
      // alert(data.createQuote.id)
      navigate('/confirmation', { state: { leadMail: values.mail } })
    }
  }, [dataQuote])

  const steps = ["L'idée", "Fonctionnalités", "Options", "Coordonnées"]

  return (
    <div className="content-wrapper">
      <Navbar bg={'bg-soft-primary'} />
      <hr className='m-0' />
      <section className="wrapper bg-soft-primary">
        <div className="container py-10 py-md-10">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <Col xs={10} className='text-center mx-auto tunnel-progress'>
                <div className="row gx-lg-8 gx-xl-12 gy-6 process-wrapper line">
                  {
                    steps.map((step, idx) =>
                      <div key={idx} className="d-flex flex-column justify-content-center align-items-center col-3"> <span className={`icon btn btn-circle btn-lg pe-none mb-4 btn-${stepNumber !== idx ? 'soft-' : ''}primary`}><span className="number">0{idx + 1}</span></span>
                        <p className={`mb-1 d-none d-md-block ${stepNumber !== idx ? 'text-secondary' : 'display-6 text-dark'}`}>{step}</p>
                      </div>
                    )
                  }
                </div>
                <p className='d-block d-md-none display-6 text-dark m-0'>{steps[stepNumber]}</p>
              </Col>
              <Form noValidate validated={validated[currentStepIndex]} className='needs-validation quote-form' onSubmit={onSubmit}>
                {step}
                <div className="d-flex justify-content-between">
                  <Button type="button" className={`rounded text-primary previous mb-3 ${isFirstStep ? 'opacity-0 pointer-none' : ''}`} onClick={back}><FontAwesomeIcon icon={faArrowLeftLong} />&nbsp;&nbsp;&nbsp;Retour</Button>
                  {!isLastStep ?
                    (<Button variant="primary" type="submit" className='btn btn-primary rounded mb-3'>
                      <p className='mb-0'>Continuer&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faArrowRightLong} /></p>
                    </Button>)
                    :
                    isValidReCaptcha &&
                    <Button variant="primary" type="submit" className='btn btn-primary rounded mb-3 px-3' disabled={!isValidReCaptcha}>
                      <p className='mb-0'>Terminer&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} /></p>
                    </Button>
                  }
                </div>
              </Form>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Tunnel