import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from './components/global/Layout'
import Home from './pages/Home'
import MultiStepForm from './pages/Tunnel'
import QuoteConfirmation from './pages/QuoteConfirmation'
import NotFound from './pages/NotFound'
import OurMethod from './pages/OurMethod'
import OurServices from './pages/OurServices'
import ContactPage from './pages/ContactPage'
import PricingPage from './pages/PricingPage'
import TermsPage from './pages/TermsPage'

const Main = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/multistep" element={<MultiStepForm />} />
          <Route path="/confirmation" element={<QuoteConfirmation />} />
          <Route path="/notre-méthode" element={<OurMethod />} />
          <Route path="/nos-services" element={<OurServices />} />
          <Route path="/nos-tarifs" element={<PricingPage />}/>
          <Route path="/nous-contacter" element={<ContactPage />} />
          <Route path="/mentions-légales" element={<TermsPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )

}

const App = () => {

  return (
    <Main />
  )

}

export default App