import Navbar from "../components/global/Navbar"
import { scrollToTop } from "../tools/common"

const TermsPage = () => {
  scrollToTop()
  return (
    <>
      <Navbar bg={'bg-soft-primary'} />
      <section className="wrapper bg-soft-primary">
        <div className="container py-14 py-md-16">
          <h2 className="display-5 mb-7 text-center">Mentions légales</h2>
          <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des Utilisateurs du site dunidée.fr les présentes mentions légales.</p>
                    <p className="mb-0">La connexion et la navigation sur le site (dunidée.fr) par l’Utilisateur impliquent acceptation intégrale et sans réserve des présentes mentions légales. Ces dernières sont accessibles sur le site à la rubrique « Mentions légales »</p>
          <div className="row gx-0">
            <aside className="col-xl-3 sidebar sticky-sidebar mt-md-0 py-16 d-none d-xl-block">
              <div className="widget">
                <nav id="sidebar-nav">
                  <ul className="list-unstyled text-reset">
                    <li><a className="nav-link scroll active" href="#terms-conditions">1. L’éditeur</a></li>
                    <li><a className="nav-link scroll" href="#privacy-policy">2. L’hébergeur</a></li>
                    <li><a className="nav-link scroll" href="#user-policy">3. L'accès au site</a></li>
                    <li><a className="nav-link scroll" href="#copyrights">4. La collecte de données</a></li>
                  </ul>
                </nav>
              </div>
            </aside>
            <div className="col-xl-8">
              <section id="terms-conditions" className="wrapper pt-10">
                <div className="card">
                  <div className="card-body p-10">
                    <h2 className="mb-3">1. L’éditeur</h2>
                    <p>L’édition du site dunidée.fr est assurée par la Société SAS DUNIDEE au capital de 1000,00 € euros, immatriculée au RCS de Paris sous le numéro 817 451 xxx dont le siège social est situé au 7 rue Evariste Galois 75020 Paris, adresse e-mail : support@dunidee.fr</p>
                    <p>N° de TVA intracommunautaire : FR48817451xxx</p>
                    <p className="mb-0">Le Directeur de la publication est Soufiane Aït Ouarraou, CEO de Dunidée.</p>
                  </div>
                </div>
              </section>
              <section id="privacy-policy" className="wrapper pt-10">
                <div className="card">
                  <div className="card-body p-10">
                    <h2 className="mb-3">2. L’hébergeur</h2>
                    <p>Le site dunidée.fr est hébergé sur IONOS (anciennement 1&1) dans l'Union européenne (Allemagne).</p>
                    <p className="mb-0">L'hébergeur du site est la société IONOS SARL dont le siège social est situé au 7 PL DE LA GARE - 57200 SARREGUEMINES</p>
                  </div>
                </div>
              </section>
              <section id="user-policy" className="wrapper pt-10">
                <div className="card">
                  <div className="card-body p-10">
                    <h2 className="mb-3">3. L'accès au site</h2>
                    <p>Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption programmée ou non et pouvant découler d’une nécessité de maintenance.</p>
                    <p className="mb-0">En cas de modification, interruption ou suspension des services le site dunidée.fr ne saurait être tenu responsable.</p>
                  </div>
                </div>
              </section>
              <section id="copyrights" className="wrapper pt-10">
                <div className="card">
                  <div className="card-body p-10">
                    <h2 className="mb-3">4. La collecte de données</h2>
                    <p>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation.</p>
                    <p>En naviguant sur le site, il choisit de les accepter ou non. Un cookie est un élément qui ne permet pas d’identifier l’Utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site Internet.</p>
                    <p className="mb-0">L’Utilisateur pourra désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default TermsPage