import { ReactNode, useEffect } from 'react'
import { scrollToTop } from '../../tools/common'

type FormWrapperProps = {
  title: string,
  subtitle?: string,
  children: ReactNode
}

export function FormWrapper({ title, children }: FormWrapperProps) {
  useEffect(() => scrollToTop(), [] )
  return <>
    {/* <h2 className="display-6 mb-3 text-center">{title}</h2> */}
    {children}
  </>
}