import React from 'react'

const HowItWorks = () => {
  return (
    <>

        <div className="row text-center">
          <div className="col-md-10 offset-md-1 col-xxl-8 offset-xxl-2">
            <h2 className="fs-16 text-uppercase text-primary mb-3">Qui sommes nous ?</h2>
            <h3 className="display-6 fw-normal mb-9">Nous développons des solutions digitales sur mesure pour les <strong>petites entreprises</strong> et les <strong>entrepreneurs</strong>.</h3>
          </div>
          {/* <!-- /column --> */}
        </div>
        {/* <!-- /.row --> */}
        <div className="row gx-md-8 gy-8 mb-10 text-center">
        <div className="col-md-6 col-lg-3">
            <div className="px-md-3 px-lg-0 px-xl-3">
              <img src="./assets/img/icons/solid/web-programming.svg" className="icon-svg icon-svg-md solid-mono text-grape mb-5" alt="" />
              <h4>Développement web & mobile</h4>
              <p className="mb-2">Nous donnons vie à vos idées en utilisant les dernières technologies du marché.</p>
            </div>
          </div>
          {/* <!--/column --> */}
          <div className="col-md-6 col-lg-3">
            <div className="px-md-3 px-lg-0 px-xl-3">
              <img src="./assets/img/icons/solid/layout.svg" className="icon-svg icon-svg-md solid-mono text-grape mb-5" alt="" />
              <h4>UX/UI Design</h4>
              <p className="mb-2">Nos compétences en design nous permettent d'élaborer des maquettes de qualité.</p>
            </div>
          </div>
          {/* <!--/column --> */}
          <div className="col-md-6 col-lg-3">
            <div className="px-md-3 px-lg-0 px-xl-3">
              <img src="./assets/img/icons/lineal/chat-2.svg" className="icon-svg icon-svg-md solid-mono text-grape mb-5" alt="" />
              <h4>Marketing & SEO</h4>
              <p className="mb-2">Parce que les mots utilisés sur votre site sont importants, nous pouvons vous accompagner.</p>
            </div>
          </div>
          {/* <!--/column --> */}
          <div className="col-md-6 col-lg-3">
            <div className="px-md-3 px-lg-0 px-xl-3">
              <img src="./assets/img/icons/solid/partnership.svg" className="icon-svg icon-svg-md solid-mono text-grape mb-5" alt="" />
              <h4>Maintenance</h4>
              <p className="mb-2">Pour que votre projet dure dans le temps, il doit être mis à jour régulièrement. </p>
            </div>
          </div>
          {/* <!--/column --> */}
        </div>
        {/* <!--/.row --> */}
    </>
  )
}

export default HowItWorks