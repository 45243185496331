import Navbar from "../components/global/Navbar"
import ContactUs from "../components/home/ContactUs"
import { scrollToTop } from "../tools/common"

const ContactPage = () => {
  scrollToTop()
  return (
    <>
      <Navbar bg={'bg-soft-primary'} />
      <section className="wrapper bg-soft-primary">
        <div className="container py-14 py-md-16">
          <ContactUs />
        </div>
      </section>
    </>
  )
}

export default ContactPage