import { Card, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import { FormWrapper } from './FormWrapper'
import { useLocation } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faLaptop, faLaptopMedical, faMobileAlt, faQuestion } from '@fortawesome/free-solid-svg-icons'

type IdeaFormValues = {
  neededProducts: string,
  category: string | undefined,
  description: string
}

type IdeaFormProps = IdeaFormValues & {
  updateFields: (fields: Partial<IdeaFormValues>) => void,
}

const products = [
  {
    name: 'website',
    label: 'Site statique',
    icon: <FontAwesomeIcon icon={faLaptop} />,
    tooltip: 'Idéal pour un site <strong>vitrine</strong>, un blog, et plus encore...'
  },
  {
    name: 'webApp',
    label: 'Site dynamique',
    icon: <FontAwesomeIcon icon={faLaptopMedical} />,
    tooltip: "Relié à une <strong>base de données</strong>, il peut faire tout ce qu'on lui demande de faire"
  },
  {
    name: 'mobileApp',
    label: 'Application',
    icon: <FontAwesomeIcon icon={faMobileAlt} />,
    tooltip: 'Nous développons des applications multi-support, pour <strong>iOS et Android</strong>'
  },
  {
    name: 'none',
    label: "Ne sais pas",
    icon: <FontAwesomeIcon icon={faQuestion} />,
    tooltip: "Nous vous proposerons la meilleure solution <strong>adaptée à votre projet</strong>",
    tooltipHeader: 'Vous ne savez pas'
  },
]

export function IdeaForm({ neededProducts, category, description, updateFields }: IdeaFormProps) {

  const location = useLocation()

  const savePickedChoice = useCallback(() => {
    if (location.state) {
      const choice = location.state.data
      if (!neededProducts.includes(choice)) {
        // ; au début je crois
        const prevChoices = neededProducts.length > 1 ? neededProducts + ';' : ''
        updateFields({ neededProducts: prevChoices + choice })
      }
    }
  }, [])

  const updateNeededProducts = (product: string) => {
    if (neededProducts.length > 1) {
      const allNeededProducts = neededProducts.split(';')
      if (allNeededProducts) {
        const index = allNeededProducts.indexOf(product)
        if (index !== -1) {
          allNeededProducts.splice(index, 1);
        } else {
          allNeededProducts.push(product);
        }
        updateFields({ neededProducts: allNeededProducts.join(';') });

      }
    } else {
      updateFields({ neededProducts: product });
    }
  }

  // saving home device btn choice
  useEffect(() => savePickedChoice(), [location.state])

  return (
    <>
      <FormWrapper title="">
        <Form.Group className='mb-4'>
          <Form.Label>Vous souhaitez*</Form.Label>
          <div className="container ">
            <div className="row">
              {
                products.map((product, idx) =>
                  <Col lg={3} xs={6} className='mb-3' key={idx}>
                    <Card className={`quote-card pointer ${neededProducts.includes(product.name) ? 'selected-quote-card' : ''}`} onClick={e => updateNeededProducts(product.name)}>
                      <div className="card-body p-3 px-5">
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          placement="bottom"
                          overlay={
                            <Popover>
                              <Popover.Header as="h6">{product.tooltipHeader || product.label}</Popover.Header>
                              <Popover.Body dangerouslySetInnerHTML={{__html: product.tooltip}} />
                            </Popover>
                          } >
                          <span className='position-absolute end-0 translate-middle'><FontAwesomeIcon icon={faInfoCircle} /></span>
                        </OverlayTrigger>
                        <div className="text-center">
                          <p className='h1'>{product.icon}</p>
                          <p className='mb-0'>{product.label}</p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                )
              }
            </div>
          </div>
        </Form.Group>
        <Form.Group className='mb-4' as={Col} lg={6}>
          <Form.Label>Catégorie*</Form.Label>
          <Form.Select required aria-label="Default select example" value={category} onChange={e => updateFields({ category: e.target.value })} className="pointer">
            <option value="">Choisissez</option>
            <option value="business">Business</option>
            <option value="vitrine">Site vitrine</option>
            <option value="logiciel">Logiciel</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className='mb-4'>
          <Form.Label>Votre idée en quelques mots*</Form.Label>
          <Form.Control
            required
            value={description}
            minLength={20} // works partially
            onChange={e => updateFields({ description: e.target.value })}
            as="textarea"
            rows={2}
            placeholder='Expliquez-nous...'
          />
          <Form.Control.Feedback type="invalid">
            20 caractères minimum
          </Form.Control.Feedback>
        </Form.Group>
      </FormWrapper>
    </>
  )
}