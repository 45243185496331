import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import App from './App'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

const configurations = {
  production: { port: 3000, uri: 'https://dunidée.fr/graphql' },
  development: { port: 3001, uri: 'http://localhost:5000/graphql' },
};

const environment = process.env.NODE_ENV ?? 'production';
// console.log(environment)
const config = (configurations as any)[environment]

const client = new ApolloClient({
  uri: config.uri,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
