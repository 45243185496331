import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";

const scriptsPath = [
  '../assets/js/theme.js',
]

const Layout = () => {
  const location = useLocation()

  // inserting theme.js after its dependences in head (my way to do it)
  useEffect(() => {
    
    scriptsPath.forEach(path => {
      // removing old script
      const toDelete = document.querySelector('script[src="' + path + '"]')
      if (toDelete) {
        toDelete.remove();
      }
      // adding new script
      const script = document.createElement("script")
      script.src = path
      document.body.appendChild(script)
    })
  }, [location])

  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;