import { useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { SEND_CONTACT_FORM } from '../../graphql/mutations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faPaperPlane, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

type Message = {
  message: string,
  status: string
}

const ContactUs = () => {

  const [contactInputs, setContactInputs] = useState({ name: '', email: '', message: '' })
  const [message, setMessage] = useState<Message>({ message: '', status: '' })

  const [sendContactForm, { data, error }] = useMutation(SEND_CONTACT_FORM)

  const onSubmit = (e: any) => {
    e.preventDefault()
    sendContactForm({
      variables: {
        data: contactInputs
      }
    })
  }

  useEffect(() => {
    if (data) {
      if (data.sendContactForm) {
        setContactInputs({ name: '', email: '', message: '' })
        setMessage({ message: 'Merci, à très vite', status: 'success' })
      } else {
        setMessage({ message: "Une erreur s'est produite", status: 'danger' })
      }
    } else if (error) {
      setMessage({ message: "Une erreur s'est produite", status: 'danger' })
    }
  }, [data, error])

  return (
    <div className="card bg-soft-primary" id="contact-form">
      <div className="card-body p-5 p-md-10 p-xl-12">
        <div className="row gx-md-8 gx-xl-12 gy-10">
          <div className="col-lg-6">
            <img src="./assets/img/icons/lineal/email.svg" className="svg-inject icon-svg icon-svg-sm mb-4" alt="" />
            <h2 className="display-4 mb-3 pe-lg-10">Une question en tête ?</h2>
            <p className="lead pe-lg-12 mb-0">Démarrez une conversation. Vous recevrez une réponse <strong>en moins de 24h</strong>.</p>
          </div>
          <div className="col-lg-6">
            <form className="needs-validation" onSubmit={onSubmit} noValidate>
              <div className="row gx-4">
                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input id="frm_name"
                      type="text"
                      name="name"
                      className="form-control border-0 pe-0" placeholder="Jane"
                      required={true}
                      data-error="First Name is required."
                      value={contactInputs.name}
                      onChange={(e) => setContactInputs({ ...contactInputs, name: e.target.value })} />
                    <label htmlFor="frm_name">Nom *</label>
                    <div className="invalid-feedback">
                      Entrez votre nom
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-4">
                    <input id="frm_email"
                      type="text"
                      name="email"
                      className="form-control border-0 pe-0" placeholder="Jane"
                      required={true}
                      data-error="Mail address is required."
                      value={contactInputs.email}
                      onChange={(e) => setContactInputs({ ...contactInputs, email: e.target.value })} />
                    <label htmlFor="frm_email">Adresse e-mail *</label>
                    <div className="invalid-feedback">
                      Entre une adresse e-mail valide
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-4">
                    <textarea id="frm_message"
                      name="message"
                      className="form-control border-0 pe-0" placeholder="Jane"
                      required={true}
                      data-error="Message is required."
                      style={{ height: '150px' }}
                      value={contactInputs.message}
                      onChange={(e) => setContactInputs({ ...contactInputs, message: e.target.value })} ></textarea>
                    <label htmlFor="frm_message">Message *</label>
                    <div className="invalid-feedback">
                      Entrez un message
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-between">
                  <p className={`fw-bold ms-2 text-${message.status}`}>{message.message.length > 1 &&  message.message}</p>
                  <Button type="submit" className="btn btn-outline-primary rounded btn-send mb-3 text-white" >Envoyer&nbsp;&nbsp;<FontAwesomeIcon icon={faPaperPlane} /></Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs