import { Link } from "react-router-dom"
import Navbar from "../components/global/Navbar"
import { scrollToTop } from "../tools/common"

const OurMethod = () => {
  scrollToTop()
  return (
    <div className="content-wrapper">
      <Navbar bg={'bg-soft-primary'} />
      <section className="wrapper bg-soft-primary our-method">
        <div className="container pt-10 pt-lg-12 pt-xl-12 pt-xxl-10 pb-10">
          <div className="row text-center mb-5">
            <div className="col-lg-9 mx-auto">
              <h3 className="display-4 px-xl-10 px-xxl-15">Imaginer. Coder. Évoluer.</h3>
              <p className="mb-0">Nous vous accompagnons dans la concrétisation de vos projets numériques.</p>
              <div className="row gx-lg-8 gx-xl-12 process-wrapper text-center">
                <h3 className="display-6 px-xl-10 px-xxl-15 my-10">Notre méthode est simple</h3>
                <div className="col-xs-12 col-md-6 col-xl-3 mb-5">
                  <img src="./assets/img/icons/lineal/headphone-2.svg" className="icon-svg icon-svg-md text-primary mb-3" alt="" />
                  <h4 className="mb-1">1. Écoute de vos besoins</h4>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3 mb-5">
                  <img src="./assets/img/icons/lineal/design.svg" className="icon-svg icon-svg-md text-red mb-3" alt="" />
                  <h4 className="mb-1">2. Conception graphique</h4>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3 mb-5">
                  <img src="./assets/img/icons/lineal/settings-3.svg" className="icon-svg icon-svg-md text-leaf mb-3" alt="" />
                  <h4 className="mb-1">3. Création du projet</h4>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-3 mb-5">
                  <img src="./assets/img/icons/lineal/refresh.svg" className="icon-svg icon-svg-md text-leaf mb-3" alt="" />
                  <h4 className="mb-1">4. Suivi et maintenance</h4>
                </div>
              </div>
              <div className="text-center mt-5">
                <Link to={"/multistep"} className="btn btn-xs btn-primary rounded px-2">
                  Demandez votre devis instantané
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OurMethod