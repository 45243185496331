import React, { useEffect, useState } from 'react';
import Navbar from '../components/global/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { scrollToTop } from '../tools/common';

function QuoteConfirmation() {

  const navigate = useNavigate()
  const location = useLocation()
  const leadMail = location.state?.leadMail

  useEffect(() => {
    if (leadMail === undefined) {
      navigate('/')
    }
  },[leadMail])

  scrollToTop()
  return (
    <>
      <div className="content-wrapper">
        <Navbar bg={'bg-soft-primary'} />
        {/* <!-- /section --> */}
        <section className="wrapper bg-soft-primary text-center">
          <div className="container pt-15 pb-15 pb-md-17">
            <p className='h1 text-success'><FontAwesomeIcon icon={faCheckCircle} /></p>
            <h3>Votre devis sur-mesure est prêt</h3>
            <p className='mb-5'>Un mail vous a été envoyé à <span className='fw-bold'>{leadMail}</span></p>
            <h4>A très vite !</h4>
          </div>
        </section>
        {/* <!-- /section --> */}
      </div>
    </>
  );
}

export default QuoteConfirmation;
