import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className="bg-dark text-inverse">
      <div className="container pt-15 pt-md-17 pb-13 pb-md-15">
        <div className="d-lg-flex flex-row align-items-lg-center">
          <h3 className="display-4 mb-6 mb-lg-0 pe-lg-20 pe-xl-22 pe-xxl-25 text-white">
            <figure className=" mb-0">
              <blockquote className="icon fs-lg">
                <p className="fst-italic">“Rien ne se perd, rien ne se crée, tout se transforme”</p>
              </blockquote>
              <figcaption className="blockquote-footer mb-0">Antoine Lavoisier</figcaption>
            </figure>
          </h3>
        </div>
        <hr className="mt-11 mb-12" />
        <div className="row gy-6 gy-lg-0">
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <img className="mb-4 dunidee-logo" src="./assets/img/logo-light.png" alt="" />
              <p className="mb-4">© 2023 Dunidée. <br className="d-none d-lg-block" />Tous droits réservés. <br />
                <Link to="/mentions-légales">Mentions légales</Link>
              </p>
              <nav className="nav social social-white">
                {/* <a href="#"><i className="uil uil-facebook-f"></i></a> */}
                <a href="https://www.instagram.com/dunidee.fr/" target="_blank" rel="noreferrer"><i className="uil uil-instagram"></i></a>
                {/* <a href="#"><i className="uil uil-youtube"></i></a> */}
              </nav>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Coordonnées</h4>
              {/* <address className="pe-xl-15 pe-xxl-17">Moonshine St. 14/05 Light City, London, United Kingdom</address> */}
              <a href="mailto:support@dunidee.fr">support@dunidee.fr</a>
            </div>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="widget">
              <h4 className="widget-title text-white mb-3">Navigation</h4>
              <ul className="list-unstyled  mb-0">
                <li><Link to="/notre-méthode">Notre méthode</Link></li>
                <li><Link to="/nos-services">Nos services</Link></li>
                <li><Link to="/nos-tarifs">Nos tarifs</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer