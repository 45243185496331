import React from 'react';
import Navbar from '../components/global/Navbar';
import Hero from '../components/home/Hero';
import HowItWorks from '../components/home/HowItWorks';
import ContactUs from '../components/home/ContactUs';
import { scrollToTop } from '../tools/common';

function Home() {
  scrollToTop()
  return (
    <>
      <div className="content-wrapper">
        <Navbar bg={'bg-soft-primary'} />
        <Hero />
        {/* <!-- /section --> */}
        <section className="wrapper bg-white">
          <div className="container pt-15 pb-15 pb-md-17">
            <HowItWorks />
            <ContactUs />
          </div>
        </section>
        {/* <!-- /section --> */}
      </div>
      {/* <!-- /.content-wrapper --> */}
      {/* <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div> */}
    </>
  );
}

export default Home;
