import { gql } from "@apollo/client";

export const CREATE_LEAD = gql`
mutation CreateLead($data: LeadInput!) {
  createLead(data: $data) {
    id
  }
}`

export const CREATE_QUOTE = gql`
mutation CreateQuote($data: QuoteInput!) {
  createQuote(data: $data) {
    id
  }
}`

export const SEND_CONTACT_FORM = gql`
mutation SendContactForm($data: MessageInput!) {
  sendContactForm(data: $data) {
    id
  }
}`