import { useEffect, useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { FormWrapper } from './FormWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

type ProductFormValues = {
  wantedFeatures: string,
  customFeatures?: string,
  needBackoffice: boolean
}

type ProductFormProps = ProductFormValues & {
  updateFields: (fields: Partial<ProductFormValues>) => void
}

const features = [
  {
    name: 'login',
    label: 'Connexion',
    active: true
  },
  {
    name: 'profile',
    label: 'Profil utilisateur',
    active: true
  },
  {
    name: 'dashboard',
    label: 'Tableau de bord',
    active: true
  },
  {
    name: 'planning',
    label: 'Planning',
    active: true
  },
  {
    name: 'bookings',
    label: 'Réservations ou commandes',
    active: true
  },
  {
    name: 'forms',
    label: 'Formulaire(s)',
    active: true
  },
  {
    name: 'searchbar',
    label: 'Barre de recherche',
    active: true
  },
  {
    name: 'geolocation',
    label: 'Géolocalisation',
    active: true
  },
  {
    name: 'paiement',
    label: 'Paiement',
    active: true
  },
  {
    name: 'chat',
    label: 'Chat',
    active: true
  },
  {
    name: 'multilingual',
    label: 'Multilingue',
    active: true
  },
  {
    name: 'notifs',
    label: 'Notifications (SMS, mail ou push)',
    active: true
  },
]

export function ProductForm({ wantedFeatures, customFeatures, needBackoffice, updateFields }: ProductFormProps) {
  
  const updateWantedFeatures = (feature: string) => {
    if (wantedFeatures.length > 1) {
      const allFeatures = wantedFeatures.split(';')
      if (allFeatures) {
        const index = allFeatures.indexOf(feature)
        if (index !== -1) {
          allFeatures.splice(index, 1);
        } else {
          allFeatures.push(feature);
        }
        
        updateFields({ wantedFeatures: allFeatures.join(';') }) ;
      }
    } else {
      updateFields({ wantedFeatures: feature });
    }
  }

  const [customFeature, setCustomFeature] = useState('')
  const [customFeaturesArray, setCustomFeaturesArray] = 
    useState<string[]>(customFeatures?.split(';') || [])

  const updateCustomFeaturesArray = (e: any) => {
    if (customFeature.length > 1) {
      setCustomFeaturesArray([ ...customFeaturesArray, customFeature ])
    }
    setCustomFeature('')
  }

  const deleteCustomFeature = (feature: string) => {
    console.log(feature)
    let index = customFeaturesArray.indexOf(feature)
    if (index > -1) {
      setCustomFeaturesArray(customFeaturesArray.filter(f => f !== feature))
    }
  }

  useEffect(() => {
    updateFields({ customFeatures: customFeaturesArray.join(';') })
  }, [customFeaturesArray])

  return (
    <FormWrapper title='Product form' subtitle='test'>
      <Form.Group className='mb-4' >
        <Form.Label>Les fonctionnalités attendues :</Form.Label>
        <div className="d-flex flex-wrap">
          {
            features.filter(f => f.active === true ).map(feature =>
              <Form.Check
                key={feature.name}
                inline
                type="checkbox"
                id={feature.name}
                label={feature.label}
                checked={wantedFeatures?.includes(feature.name)}
                onChange={e => updateWantedFeatures(feature.name)}
              />
            )
          }
        </div>
      </Form.Group>
      <Form.Group className='mb-4' >
        <Form.Label>Autres fonctionnalités :</Form.Label>
        <div className='custom-features mb-3'>
          {
            customFeaturesArray.filter(f => f.length > 0).map(feature =>
              <span key={feature} className="badge badge-lg bg-primary text-white rounded m-1">
                <div className="d-flex">
                  <p className='mb-0 me-3'>{feature}</p>
                  <FontAwesomeIcon className='pointer' icon={faXmarkCircle} onClick={e => deleteCustomFeature(feature)} />
                </div>
              </span>
            )
          }
        </div>
        <Col md={6} className='d-flex'>
          <Form.Control className='me-3' type='text' placeholder='Entrez votre fonctionnalité' value={customFeature} onChange={e => setCustomFeature(e.target.value)} />
          <Button type='button' onClick={e => updateCustomFeaturesArray(e)} disabled={customFeaturesArray.includes(customFeature)}>Ajouter</Button>
        </Col>
      </Form.Group>
      <Form.Group className='mb-4' > 
      {/* ajouter info */}
        <Form.Check
          type="switch"
          id="custom-switch"
          label="Besoin d'un backoffice ?"
          checked={needBackoffice}
          onChange={e => updateFields({ needBackoffice: !needBackoffice })}
        />
      </Form.Group>
    </FormWrapper>
  )
}