import { faDesktop, faLaptop, faLightbulb, faMobile, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const Hero = () => {

  return (
    <section className="wrapper bg-soft-primary">
      <div className="container pt-10 pt-lg-12 pt-xl-12 pt-xxl-10 pb-lg-10 pb-xl-10 pb-xxl-0">
        <div className="row gx-md-8 gx-xl-12 gy-10 align-items-center text-center text-lg-start">
          <div className="col-lg-6" data-group="page-title">
            <h1 className="display-1 mb-4 me-xl-5 mt-lg-n10">Parce que tout part <br className="d-none d-md-block d-lg-none" /><span className="text-primary">d'une simple <span className="underline-3 style-3 yellow">idée</span></span></h1>
            <p className="lead fs-20 lh-sm mb-7 pe-xxl-15">Exprimez-la dès aujourd'hui, <br className="d-none d-md-block d-lg-none" />on s'occupe du reste</p>
            <div className='shadow bg-primary rounded p-3 px-5 mx-auto mx-lg-0 w-100 instant-quote'>
              <h3 className='text-white mb-4'>Vous avez une idée de :</h3>
              <div className="btn-group mb-2">
                <div className="d-inline-flex me-2">
                  <Link to={"/multistep"} state={{ data: 'website' }} className="btn btn-lg btn-white rounded text-dark px-2 device-choice">
                  <FontAwesomeIcon icon={faLaptop} />&nbsp;&nbsp;Site internet
                  </Link>
                </div>
                <div className="d-inline-flex">
                  <Link to={"/multistep"} state={{ data: 'mobileApp' }} className="btn btn-lg btn-white rounded text-dark px-2 device-choice">
                  <FontAwesomeIcon icon={faMobileAlt} />&nbsp;&nbsp;Application
                  </Link>
                </div>
              </div>
              <Link to={"/multistep"} state={{ data: 'none' }}>
                <p className='fw-bold text-white mb-1 px-1'>Je ne sais pas encore</p>
              </Link>
            </div>
          </div>
          {/* <!--/column --> */}
          <div className="col-10 col-md-7 mx-auto col-lg-6 col-xl-5 ms-xl-5 mascotte" style={{ userSelect: 'none' }}>
            <img className="img-fluid mb-n12 mb-md-n14 mb-lg-n19" src="./assets/img/illustrations/mascotte.png" alt="" unselectable='on' style={{ userSelect: 'none' }} />
          </div>
          {/* <!--/column --> */}
        </div>
        {/* <!-- /.row --> */}
      </div>
      {/* <!-- /.container --> */}
      <figure><img src="./assets/img/photos/clouds.png" alt="" /></figure>
    </section>
  )
}

export default Hero