import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

type NavbarProps = {
  bg: string
}

const Navbar = ({ bg }: NavbarProps) => {
  return (
    <header className={`wrapper ${bg}`}>
      <nav className="navbar navbar-expand-lg center-nav transparent navbar-light pt-0">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand">
            <Link to={'/'}>
              <img className='dunidee-logo' src="./assets/img/dunidee.png" alt="" />
            </Link>
          </div>
          <div className='d-flex'>
            <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start me-3">
              <div className="offcanvas-header d-lg-none d-flex justify-content-end pb-0">
                <button type="button" className="btn-close btn-close-white float-end" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link className="nav-link" to={"/notre-méthode"}>Notre méthode</Link>
                    {/* <!--/.dropdown-menu --> */}
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link" to={"/nos-services"}>Nos services</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link" to={"/nos-tarifs"}>Nos tarifs</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link" to={"/nous-contacter"}>Contactez-nous</Link>
                  </li>
                </ul>
                {/* <!-- /.navbar-nav --> */}
                <div className="offcanvas-footer d-lg-none">
                  <div>
                    <Link to={'/'}>
                      <img className="mb-5 dunidee-logo" src="./assets/img/logo-light.png" alt="" />
                    </Link>
                    <a href="mailto:first.last@email.com" className="link-inverse">support@dunidee.fr</a>
                    <nav className="nav social social-white mt-4">
                      {/* <a href="#"><i className="uil uil-facebook-f"></i></a> */}
                      <a href="https://www.instagram.com/dunidee.fr/" target="_blank"><i className="uil uil-instagram"></i></a>
                      {/* <a href="#"><i className="uil uil-youtube"></i></a> */}
                    </nav>
                    {/* <!-- /.social --> */}
                  </div>
                </div>
                {/* <!-- /.offcanvas-footer --> */}
              </div>
              {/* <!-- /.offcanvas-body --> */}
            </div>
            {/* <!-- /.navbar-collapse --> */}
            <div className="navbar-other w-100 d-flex ms-auto">
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                <li className="nav-item d-lg-none">
                  <button className="hamburger offcanvas-nav-btn"><span></span></button>
                </li>
              </ul>
              {/* <!-- /.navbar-nav --> */}
            </div>
            {/* <!-- /.navbar-other --> */}
          </div>
        </div>
        {/* <!-- /.container --> */}
      </nav>
    </header>
  )
}

export default Navbar