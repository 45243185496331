import { Link } from "react-router-dom"
import Navbar from "../components/global/Navbar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLaptop, faLaptopMedical, faMobileAlt, faRefresh } from "@fortawesome/free-solid-svg-icons"
import { scrollToTop } from "../tools/common"

const PricingPage = () => {
  scrollToTop()
  return (
    <>
      <Navbar bg={'bg-soft-primary'} />
      <section className="wrapper bg-soft-primary">
        <div className="container py-14 py-md-16">
          <h2 className="display-5 mb-7 text-center">Nos tarifs</h2>
          <div className="pricing-wrapper">
            <div className="row gx-2 gy-6 mt-2">
              <div className="col-md-6 col-lg-3">
                <div className="pricing card shadow-none">
                  <div className="card-body ms-5">
                    <div className="icon btn btn-circle btn-lg btn-soft-primary pe-none"> <FontAwesomeIcon icon={faLaptop} /> </div>
                    <h4 className="card-title">Site statique</h4>

                    <div className="prices text-dark">
                      <small>à partir de</small>
                      <div className="price price-show justify-content-start"><span className="price-value">350 €</span><span className="small">TTC</span></div>
                    </div>
                    <ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8">
                      <li><i className="uil uil-check"></i><span>Multi-pages</span></li>
                      <li><i className="uil uil-check"></i><span>Site vitrine</span></li>
                      <li><i className="uil uil-check"></i><span>Blog</span></li>
                      <li><i className="uil uil-times bullet-soft-red"></i><span>Base de données</span></li>
                    </ul>
                    <Link to={'/multistep'} state={{ data: 'website' }}>
                      <button className="btn btn-soft-primary rounded-pill">Choisir</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="pricing card shadow-none">
                  <div className="card-body ms-5">
                    <div className="icon btn btn-circle btn-lg btn-soft-primary pe-none"> <FontAwesomeIcon icon={faLaptopMedical} /> </div>
                    <h4 className="card-title">Site dynamique</h4>

                    <div className="prices text-dark">
                      <small>à partir de</small>
                      <div className="price price-show justify-content-start"><span className="price-value">850 €</span><span className="small">TTC</span></div>
                    </div>
                    <ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8">
                      <li><i className="uil uil-check"></i><span>Base de données</span></li>
                      <li><i className="uil uil-check"></i><span>Back-office</span></li>
                      <li><i className="uil uil-check"></i><span>Maintenance <strong>2 semaines</strong> offerte</span></li>
                    </ul>
                    <Link to={'/multistep'} state={{ data: 'webApp' }}>
                      <button className="btn btn-soft-primary rounded-pill">Choisir</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="pricing card shadow-none">
                <div className="card-body ms-5">
                    <div className="icon btn btn-circle btn-lg btn-soft-primary pe-none"> <FontAwesomeIcon icon={faMobileAlt} /></div>
                    <h4 className="card-title">Application mobile</h4>

                    <div className="prices text-dark">
                      <small>à partir de</small>
                      <div className="price price-show justify-content-start"><span className="price-value">950 €</span><span className="small">TTC</span></div>
                    </div>
                    <ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8">
                      <li><i className="uil uil-check"></i><span>Pour <strong>iOS</strong> & <strong>Android</strong></span></li>
                      <li><i className="uil uil-check"></i><span>App Store & Play Store</span></li>
                      <li><i className="uil uil-check"></i><span>Maintenance <strong>2 semaines</strong> offerte</span></li>
                    </ul>
                    <Link to={'/multistep'} state={{ data: 'mobileApp' }}>
                      <button className="btn btn-soft-primary rounded-pill">Choisir</button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="pricing card shadow-none">
                <div className="card-body ms-5">
                    <div className="icon btn btn-circle btn-lg btn-soft-primary pe-none"> <FontAwesomeIcon icon={faRefresh} /></div>
                    <h4 className="card-title">Maintenance</h4>

                    <div className="prices text-dark">
                      <small>à partir de</small>
                      <div className="price price-show justify-content-start"><span className="price-value">25 €</span><span className="price-duration">mois</span></div>
                    </div>
                    <ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8">
                      <li><i className="uil uil-check"></i><span>Retouche du contenu</span></li>
                      <li><i className="uil uil-check"></i><span>Contrôle de la mise en ligne</span></li>
                      <li><i className="uil uil-check"></i><span>Mises à jour</span></li>
                    </ul>
                    <Link to={'/nous-contacter'} >
                      <button className="btn btn-soft-primary rounded-pill">Nous contacter</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PricingPage