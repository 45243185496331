import { Link } from "react-router-dom"
import Navbar from "../components/global/Navbar"
import { scrollToTop } from "../tools/common"

const NotFound = () => {
  scrollToTop()
  return (
    <>
      <Navbar bg={'bg-soft-primary'} />
      <section className="wrapper bg-soft-primary">
        <div className="container py-10 py-lg-12 py-xl-12 py-xxl-10 text-center">
          <h1 className="display-1 my-5">Erreur 404</h1>
          <h2 className="mt-5">Oops, cette page n'existe pas</h2>
          <Link to={'/'}>
            <button className="btn btn-primary mt-5">Revenir à l'accueil</button>
          </Link>
        </div>
      </section>
    </>
  )
}

export default NotFound