import { Link } from "react-router-dom"
import Navbar from "../components/global/Navbar"
import { scrollToTop } from "../tools/common"

const OurServices = () => {
  scrollToTop()
  return (
    <>
      <div className="content-wrapper">
        <Navbar bg={'bg-soft-primary'} />
        <section className="wrapper bg-soft-primary">
          <div className="container py-14 py-md-16">
            <div className="row gx-lg-0 gy-10 align-items-center">
              <div className="col-lg-5 offset-lg-1 order-lg-2">
                <h2 className="display-3 mb-3">Une expertise à la hauteur de vos idées</h2>
                <p className="lead fs-lg">Que vous ayez besoin d'un site web simple ou d'une plateforme complexe, nous avons l'expertise et les ressources nécessaires pour donner vie à votre vision.</p>
                <p>Dès la première consultation, nous travaillons en étroite collaboration avec nos clients pour nous assurer que leurs objectifs et leurs besoins sont pleinement compris et intégrés au projet. Notre équipe de designers et de développeurs qualifiés utilise ensuite les dernières technologies et les meilleures pratiques pour créer un site web de haute qualité, réactif et convivial, optimisé à la fois pour les moteurs de recherche et pour les conversions.</p>
                <div className="text-end mt-5">
                <Link to={"/multistep"} className="btn btn-xs btn-primary rounded px-2">
                  Demandez votre devis instantané
                </Link>
              </div>
              </div>
              <div className="col-lg-6">
                <div className="row g-6 text-center">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card shadow-lg mb-6">
                          <div className="card-body">
                            <img src="./assets/img/icons/solid/web-programming.svg" className="icon-svg icon-svg-sm solid-duo text-purple-aqua mb-3" alt="" />
                            <h3>Développement web & mobile</h3>
                            <p className="mb-2">Nous donnons vie à vos idées en utilisant les dernières technologies du marché.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="card shadow-lg">
                          <div className="card-body">
                            <img src="./assets/img/icons/solid/layout.svg" className="icon-svg icon-svg-sm solid-duo text-purple-aqua mb-3" alt="" />
                            <h3>UX/UI Design</h3>
                            <p className="mb-2">Nos compétences en design nous permettent d'élaborer des maquettes de qualité.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-lg-12 order-md-2">
                        <div className="card shadow-lg mb-6 mb-md-0">
                          <div className="card-body">
                            <img src="./assets/img/icons/lineal/chat-2.svg" className="icon-svg icon-svg-sm solid-duo text-purple-aqua mb-3" alt="" />
                            <h3>Marketing & SEO</h3>
                            <p className="mb-2">Parce que les mots utilisés sur votre site sont importants, nous pouvons vous accompagner.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="card shadow-lg mb-md-6 mt-lg-6">
                          <div className="card-body">
                            <img src="./assets/img/icons/solid/partnership.svg" className="icon-svg icon-svg-sm solid-duo text-purple-aqua mb-3" alt="" />
                            <h3>Maintenance</h3>
                            <p className="mb-2">Pour que projet dure dans le temps, il doit être mis à jour régulièrement.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default OurServices